import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getUserProfile } from "../redux/actions/userActions";
import { baseURLFile } from "../constants";

const DropdownProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  const profileUser = useSelector((state) => state.getProfileUser);
  const {
    loadingUserProfile,
    userProfile,
    successUserProfile,
    errorUserProfile,
  } = profileUser;

  const redirect = "/";
  useEffect(() => {
    if (userInfo) {
      dispatch(getUserProfile());
    }
  }, [userInfo]);

  return (
    <li className="relative">
      <Link
        to="/profile"
        className="relative flex h-10 w-10 items-center justify-center rounded-full border border-stroke overflow-hidden bg-gray hover:text-primary dark:border-strokedark dark:bg-meta-4 dark:text-white"
      >
        {userProfile?.photo ? (
          <img
            src={`${baseURLFile}${userProfile.photo}`}
            className="h-full w-full rounded-full object-cover"
            alt="User Profile"
            loading="lazy"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.style.display = "none"; // Hide broken image
              e.target.parentNode.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="fill-current text-gray-500 w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
            </svg>
          `;
            }}
          />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="fill-current text-gray-500 w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
            />
          </svg>
        )}
      </Link>
    </li>
  );
};

export default DropdownProfile;
