export const baseURL = "https://api-crm.unmcrm.com/api";
export const baseURLFile = "https://api-crm.unmcrm.com";

// export const baseURL = "http://192.168.224.128:8000/api";
// export const baseURLFile = "http://192.168.224.128:8000";

export const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const validatePhone = (phone) => {
  const phonePattern = /^\+?\d{8,15}$/;
  return phonePattern.test(phone);
};

export const validateLocationX = (value) => {
  const num = parseFloat(value);
  // Check if it is a number and within the range of -180 to 180
  return !isNaN(num) && num >= -180 && num <= 180;
};

export const validateLocationY = (value) => {
  const num = parseFloat(value);
  return !isNaN(num) && num >= -180 && num <= 180;
};

export const SERVICESPECIALIST = [
  "Cardiologist (Heart Specialist)",
  // "Dermatologist (Skin Specialist)",
  "Nephrologist (Kidney Specialist)",
  "Neurologist (Nervous System Specialist)",
  // "Ophthalmologist (Eye Specialist)",
  "Orthopedist (Bone/Joint Specialist)",
  "Otorhinolaryngologist (Ear, Nose, Throat Specialist)",
  "Urologist (Urinary System Specialist)",
];

export const SERVICETYPE = [
  "GP",
  "ER",
  "HC",
  "Teleconsult",
  "Ambulance tansport",
  "Imaging",
  "Physiotherapy",
  "Psychiatrist",
  "Dentist",
  "Repatriation",
  "Tow-transport",
  "Private transport (Uber/taxis...)",
  "ENT",
  "Ophthalmologist",
  "Orthopedic",
  "Pediatric",
  "Dermatologist",
  "Labwork",
  "Specialists",
];

export const CURRENCYITEMS = [
  {
    name: "",
    symbol_native: "",
    symbol: "",
    code: "",
    name_plural: "",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Afghan Afghani",
    symbol_native: "؋",
    symbol: "Af",
    code: "AFN",
    name_plural: "Afghan Afghanis",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Albanian Lek",
    symbol_native: "Lek",
    symbol: "ALL",
    code: "ALL",
    name_plural: "Albanian lekë",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Algerian Dinar",
    symbol_native: "د.ج.‏",
    symbol: "DA",
    code: "DZD",
    name_plural: "Algerian dinars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Argentine Peso",
    symbol_native: "$",
    symbol: "AR$",
    code: "ARS",
    name_plural: "Argentine pesos",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Armenian Dram",
    symbol_native: "դր.",
    symbol: "AMD",
    code: "AMD",
    name_plural: "Armenian drams",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Australian Dollar",
    symbol_native: "$",
    symbol: "AU$",
    code: "AUD",
    name_plural: "Australian dollars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Azerbaijani Manat",
    symbol_native: "ман.",
    symbol: "man.",
    code: "AZN",
    name_plural: "Azerbaijani manats",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Bahraini Dinar",
    symbol_native: "د.ب.‏",
    symbol: "BD",
    code: "BHD",
    name_plural: "Bahraini dinars",
    rounding: 0,
    decimal_digits: 3,
  },
  {
    name: "Bangladeshi Taka",
    symbol_native: "৳",
    symbol: "Tk",
    code: "BDT",
    name_plural: "Bangladeshi takas",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Belarusian Ruble",
    symbol_native: "руб.",
    symbol: "Br",
    code: "BYN",
    name_plural: "Belarusian rubles",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Belize Dollar",
    symbol_native: "$",
    symbol: "BZ$",
    code: "BZD",
    name_plural: "Belize dollars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Bolivian Boliviano",
    symbol_native: "Bs",
    symbol: "Bs",
    code: "BOB",
    name_plural: "Bolivian bolivianos",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Bosnia-Herzegovina Convertible Mark",
    symbol_native: "KM",
    symbol: "KM",
    code: "BAM",
    name_plural: "Bosnia-Herzegovina convertible marks",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Botswanan Pula",
    symbol_native: "P",
    symbol: "BWP",
    code: "BWP",
    name_plural: "Botswanan pulas",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Brazilian Real",
    symbol_native: "R$",
    symbol: "R$",
    code: "BRL",
    name_plural: "Brazilian reals",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "British Pound",
    symbol_native: "£",
    symbol: "£",
    code: "GBP",
    name_plural: "British pounds sterling",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Brunei Dollar",
    symbol_native: "$",
    symbol: "BN$",
    code: "BND",
    name_plural: "Brunei dollars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Bulgarian Lev",
    symbol_native: "лв.",
    symbol: "BGN",
    code: "BGN",
    name_plural: "Bulgarian leva",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Burundian Franc",
    symbol_native: "FBu",
    symbol: "FBu",
    code: "BIF",
    name_plural: "Burundian francs",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Cambodian Riel",
    symbol_native: "៛",
    symbol: "KHR",
    code: "KHR",
    name_plural: "Cambodian riels",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Canadian Dollar",
    symbol_native: "$",
    symbol: "CA$",
    code: "CAD",
    name_plural: "Canadian dollars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Cape Verdean Escudo",
    symbol_native: "CV$",
    symbol: "CV$",
    code: "CVE",
    name_plural: "Cape Verdean escudos",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Central African CFA Franc",
    symbol_native: "FCFA",
    symbol: "FCFA",
    code: "XAF",
    name_plural: "CFA francs BEAC",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Chilean Peso",
    symbol_native: "$",
    symbol: "CL$",
    code: "CLP",
    name_plural: "Chilean pesos",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Chinese Yuan",
    symbol_native: "CN¥",
    symbol: "CN¥",
    code: "CNY",
    name_plural: "Chinese yuan",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Colombian Peso",
    symbol_native: "$",
    symbol: "CO$",
    code: "COP",
    name_plural: "Colombian pesos",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Comorian Franc",
    symbol_native: "FC",
    symbol: "CF",
    code: "KMF",
    name_plural: "Comorian francs",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Congolese Franc",
    symbol_native: "FrCD",
    symbol: "CDF",
    code: "CDF",
    name_plural: "Congolese francs",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Costa Rican Colón",
    symbol_native: "₡",
    symbol: "₡",
    code: "CRC",
    name_plural: "Costa Rican colóns",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Croatian Kuna",
    symbol_native: "kn",
    symbol: "kn",
    code: "HRK",
    name_plural: "Croatian kunas",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Czech Koruna",
    symbol_native: "Kč",
    symbol: "Kč",
    code: "CZK",
    name_plural: "Czech Republic korunas",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Danish Krone",
    symbol_native: "kr",
    symbol: "Dkr",
    code: "DKK",
    name_plural: "Danish kroner",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Djiboutian Franc",
    symbol_native: "Fdj",
    symbol: "Fdj",
    code: "DJF",
    name_plural: "Djiboutian francs",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Dominican Peso",
    symbol_native: "RD$",
    symbol: "RD$",
    code: "DOP",
    name_plural: "Dominican pesos",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Egyptian Pound",
    symbol_native: "ج.م.‏",
    symbol: "EGP",
    code: "EGP",
    name_plural: "Egyptian pounds",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Eritrean Nakfa",
    symbol_native: "Nfk",
    symbol: "Nfk",
    code: "ERN",
    name_plural: "Eritrean nakfas",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Estonian Kroon",
    symbol_native: "kr",
    symbol: "Ekr",
    code: "EEK",
    name_plural: "Estonian kroons",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Ethiopian Birr",
    symbol_native: "Br",
    symbol: "Br",
    code: "ETB",
    name_plural: "Ethiopian birrs",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Euro",
    symbol_native: "€",
    symbol: "€",
    code: "EUR",
    name_plural: "euros",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Georgian Lari",
    symbol_native: "GEL",
    symbol: "GEL",
    code: "GEL",
    name_plural: "Georgian laris",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Ghanaian Cedi",
    symbol_native: "GH₵",
    symbol: "GH₵",
    code: "GHS",
    name_plural: "Ghanaian cedis",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Guatemalan Quetzal",
    symbol_native: "Q",
    symbol: "GTQ",
    code: "GTQ",
    name_plural: "Guatemalan quetzals",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Guinean Franc",
    symbol_native: "FG",
    symbol: "FG",
    code: "GNF",
    name_plural: "Guinean francs",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Honduran Lempira",
    symbol_native: "L",
    symbol: "HNL",
    code: "HNL",
    name_plural: "Honduran lempiras",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Hong Kong Dollar",
    symbol_native: "$",
    symbol: "HK$",
    code: "HKD",
    name_plural: "Hong Kong dollars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Hungarian Forint",
    symbol_native: "Ft",
    symbol: "Ft",
    code: "HUF",
    name_plural: "Hungarian forints",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Icelandic Króna",
    symbol_native: "kr",
    symbol: "Ikr",
    code: "ISK",
    name_plural: "Icelandic krónur",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Indian Rupee",
    symbol_native: "টকা",
    symbol: "Rs",
    code: "INR",
    name_plural: "Indian rupees",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Indonesian Rupiah",
    symbol_native: "Rp",
    symbol: "Rp",
    code: "IDR",
    name_plural: "Indonesian rupiahs",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Iranian Rial",
    symbol_native: "﷼",
    symbol: "IRR",
    code: "IRR",
    name_plural: "Iranian rials",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Iraqi Dinar",
    symbol_native: "د.ع.‏",
    symbol: "IQD",
    code: "IQD",
    name_plural: "Iraqi dinars",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Israeli New Shekel",
    symbol_native: "₪",
    symbol: "₪",
    code: "ILS",
    name_plural: "Israeli new sheqels",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Jamaican Dollar",
    symbol_native: "$",
    symbol: "J$",
    code: "JMD",
    name_plural: "Jamaican dollars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Japanese Yen",
    symbol_native: "￥",
    symbol: "¥",
    code: "JPY",
    name_plural: "Japanese yen",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Jordanian Dinar",
    symbol_native: "د.أ.‏",
    symbol: "JD",
    code: "JOD",
    name_plural: "Jordanian dinars",
    rounding: 0,
    decimal_digits: 3,
  },
  {
    name: "Kazakhstani Tenge",
    symbol_native: "тңг.",
    symbol: "KZT",
    code: "KZT",
    name_plural: "Kazakhstani tenges",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Kenyan Shilling",
    symbol_native: "Ksh",
    symbol: "Ksh",
    code: "KES",
    name_plural: "Kenyan shillings",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Kuwaiti Dinar",
    symbol_native: "د.ك.‏",
    symbol: "KD",
    code: "KWD",
    name_plural: "Kuwaiti dinars",
    rounding: 0,
    decimal_digits: 3,
  },
  {
    name: "Latvian Lats",
    symbol_native: "Ls",
    symbol: "Ls",
    code: "LVL",
    name_plural: "Latvian lati",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Lebanese Pound",
    symbol_native: "ل.ل.‏",
    symbol: "LB£",
    code: "LBP",
    name_plural: "Lebanese pounds",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Libyan Dinar",
    symbol_native: "د.ل.‏",
    symbol: "LD",
    code: "LYD",
    name_plural: "Libyan dinars",
    rounding: 0,
    decimal_digits: 3,
  },
  {
    name: "Lithuanian Litas",
    symbol_native: "Lt",
    symbol: "Lt",
    code: "LTL",
    name_plural: "Lithuanian litai",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Macanese Pataca",
    symbol_native: "MOP$",
    symbol: "MOP$",
    code: "MOP",
    name_plural: "Macanese patacas",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Macedonian Denar",
    symbol_native: "MKD",
    symbol: "MKD",
    code: "MKD",
    name_plural: "Macedonian denari",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Malagasy Ariary",
    symbol_native: "MGA",
    symbol: "MGA",
    code: "MGA",
    name_plural: "Malagasy Ariaries",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Malaysian Ringgit",
    symbol_native: "RM",
    symbol: "RM",
    code: "MYR",
    name_plural: "Malaysian ringgits",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Mauritian Rupee",
    symbol_native: "MURs",
    symbol: "MURs",
    code: "MUR",
    name_plural: "Mauritian rupees",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Mexican Peso",
    symbol_native: "$",
    symbol: "MX$",
    code: "MXN",
    name_plural: "Mexican pesos",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Moldovan Leu",
    symbol_native: "MDL",
    symbol: "MDL",
    code: "MDL",
    name_plural: "Moldovan lei",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Moroccan Dirham",
    symbol_native: "د.م.‏",
    symbol: "MAD",
    code: "MAD",
    name_plural: "Moroccan dirhams",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Mozambican Metical",
    symbol_native: "MTn",
    symbol: "MTn",
    code: "MZN",
    name_plural: "Mozambican meticals",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Myanmar Kyat",
    symbol_native: "K",
    symbol: "MMK",
    code: "MMK",
    name_plural: "Myanma kyats",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Namibian Dollar",
    symbol_native: "N$",
    symbol: "N$",
    code: "NAD",
    name_plural: "Namibian dollars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Nepalese Rupee",
    symbol_native: "नेरू",
    symbol: "NPRs",
    code: "NPR",
    name_plural: "Nepalese rupees",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "New Taiwan Dollar",
    symbol_native: "NT$",
    symbol: "NT$",
    code: "TWD",
    name_plural: "New Taiwan dollars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "New Zealand Dollar",
    symbol_native: "$",
    symbol: "NZ$",
    code: "NZD",
    name_plural: "New Zealand dollars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Nicaraguan Córdoba",
    symbol_native: "C$",
    symbol: "C$",
    code: "NIO",
    name_plural: "Nicaraguan córdobas",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Nigerian Naira",
    symbol_native: "₦",
    symbol: "₦",
    code: "NGN",
    name_plural: "Nigerian nairas",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Norwegian Krone",
    symbol_native: "kr",
    symbol: "Nkr",
    code: "NOK",
    name_plural: "Norwegian kroner",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Omani Rial",
    symbol_native: "ر.ع.‏",
    symbol: "OMR",
    code: "OMR",
    name_plural: "Omani rials",
    rounding: 0,
    decimal_digits: 3,
  },
  {
    name: "Pakistani Rupee",
    symbol_native: "₨",
    symbol: "PKRs",
    code: "PKR",
    name_plural: "Pakistani rupees",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Panamanian Balboa",
    symbol_native: "B/.",
    symbol: "B/.",
    code: "PAB",
    name_plural: "Panamanian balboas",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Paraguayan Guarani",
    symbol_native: "₲",
    symbol: "₲",
    code: "PYG",
    name_plural: "Paraguayan guaranis",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Peruvian Sol",
    symbol_native: "S/.",
    symbol: "S/.",
    code: "PEN",
    name_plural: "Peruvian nuevos soles",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Philippine Peso",
    symbol_native: "₱",
    symbol: "₱",
    code: "PHP",
    name_plural: "Philippine pesos",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Polish Zloty",
    symbol_native: "zł",
    symbol: "zł",
    code: "PLN",
    name_plural: "Polish zlotys",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Qatari Rial",
    symbol_native: "ر.ق.‏",
    symbol: "QR",
    code: "QAR",
    name_plural: "Qatari rials",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Romanian Leu",
    symbol_native: "RON",
    symbol: "RON",
    code: "RON",
    name_plural: "Romanian lei",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Russian Ruble",
    symbol_native: "₽.",
    symbol: "RUB",
    code: "RUB",
    name_plural: "Russian rubles",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Rwandan Franc",
    symbol_native: "FR",
    symbol: "RWF",
    code: "RWF",
    name_plural: "Rwandan francs",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Saudi Riyal",
    symbol_native: "ر.س.‏",
    symbol: "SR",
    code: "SAR",
    name_plural: "Saudi riyals",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Serbian Dinar",
    symbol_native: "дин.",
    symbol: "din.",
    code: "RSD",
    name_plural: "Serbian dinars",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Singapore Dollar",
    symbol_native: "$",
    symbol: "S$",
    code: "SGD",
    name_plural: "Singapore dollars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Somali Shilling",
    symbol_native: "Ssh",
    symbol: "Ssh",
    code: "SOS",
    name_plural: "Somali shillings",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "South African Rand",
    symbol_native: "R",
    symbol: "R",
    code: "ZAR",
    name_plural: "South African rand",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "South Korean Won",
    symbol_native: "₩",
    symbol: "₩",
    code: "KRW",
    name_plural: "South Korean won",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Sri Lankan Rupee",
    symbol_native: "SL Re",
    symbol: "SLRs",
    code: "LKR",
    name_plural: "Sri Lankan rupees",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Sudanese Pound",
    symbol_native: "SDG",
    symbol: "SDG",
    code: "SDG",
    name_plural: "Sudanese pounds",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Swedish Krona",
    symbol_native: "kr",
    symbol: "Skr",
    code: "SEK",
    name_plural: "Swedish kronor",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Swiss Franc",
    symbol_native: "CHF",
    symbol: "CHF",
    code: "CHF",
    name_plural: "Swiss francs",
    rounding: 0.05,
    decimal_digits: 2,
  },
  {
    name: "Syrian Pound",
    symbol_native: "ل.س.‏",
    symbol: "SY£",
    code: "SYP",
    name_plural: "Syrian pounds",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Tanzanian Shilling",
    symbol_native: "TSh",
    symbol: "TSh",
    code: "TZS",
    name_plural: "Tanzanian shillings",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Thai Baht",
    symbol_native: "฿",
    symbol: "฿",
    code: "THB",
    name_plural: "Thai baht",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Tongan Paʻanga",
    symbol_native: "T$",
    symbol: "T$",
    code: "TOP",
    name_plural: "Tongan paʻanga",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Trinidad & Tobago Dollar",
    symbol_native: "$",
    symbol: "TT$",
    code: "TTD",
    name_plural: "Trinidad and Tobago dollars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Tunisian Dinar",
    symbol_native: "د.ت.‏",
    symbol: "DT",
    code: "TND",
    name_plural: "Tunisian dinars",
    rounding: 0,
    decimal_digits: 3,
  },
  {
    name: "Turkish Lira",
    symbol_native: "₺",
    symbol: "TL",
    code: "TRY",
    name_plural: "Turkish Lira",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "US Dollar",
    symbol_native: "$",
    symbol: "$",
    code: "USD",
    name_plural: "US dollars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Ugandan Shilling",
    symbol_native: "USh",
    symbol: "USh",
    code: "UGX",
    name_plural: "Ugandan shillings",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Ukrainian Hryvnia",
    symbol_native: "₴",
    symbol: "₴",
    code: "UAH",
    name_plural: "Ukrainian hryvnias",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "United Arab Emirates Dirham",
    symbol_native: "د.إ.‏",
    symbol: "AED",
    code: "AED",
    name_plural: "UAE dirhams",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Uruguayan Peso",
    symbol_native: "$",
    symbol: "$U",
    code: "UYU",
    name_plural: "Uruguayan pesos",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Uzbekistani Som",
    symbol_native: "UZS",
    symbol: "UZS",
    code: "UZS",
    name_plural: "Uzbekistan som",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Venezuelan Bolívar",
    symbol_native: "Bs.F.",
    symbol: "Bs.F.",
    code: "VEF",
    name_plural: "Venezuelan bolívars",
    rounding: 0,
    decimal_digits: 2,
  },
  {
    name: "Vietnamese Dong",
    symbol_native: "₫",
    symbol: "₫",
    code: "VND",
    name_plural: "Vietnamese dong",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "West African CFA Franc",
    symbol_native: "CFA",
    symbol: "CFA",
    code: "XOF",
    name_plural: "CFA francs BCEAO",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Yemeni Rial",
    symbol_native: "ر.ي.‏",
    symbol: "YR",
    code: "YER",
    name_plural: "Yemeni rials",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Zambian Kwacha",
    symbol_native: "ZK",
    symbol: "ZK",
    code: "ZMK",
    name_plural: "Zambian kwachas",
    rounding: 0,
    decimal_digits: 0,
  },
  {
    name: "Zimbabwean Dollar",
    symbol_native: "ZWL$",
    symbol: "ZWL$",
    code: "ZWL",
    name_plural: "Zimbabwean Dollar",
    rounding: 0,
    decimal_digits: 0,
  },
];

export const COUNTRIES = [
  {
    title: "",
    value: "",
    icon: "",
  },
  {
    title: "Austria",
    value: "AT",
    icon: "🇦🇹",
  },
  {
    title: "Afghanistan",
    value: "AF",
    icon: "🇦🇫",
  },
  {
    title: "Albania",
    value: "AL",
    icon: "🇦🇱",
  },
  {
    title: "Algeria",
    value: "DZ",
    icon: "🇩🇿",
  },
  {
    title: "American Samoa",
    value: "AS",
    icon: "🇦🇸",
  },
  {
    title: "Andorra",
    value: "AD",
    icon: "🇦🇩",
  },
  {
    title: "Angola",
    value: "AO",
    icon: "🇦🇴",
  },
  {
    title: "Anguilla",
    value: "AI",
    icon: "🇦🇮",
  },
  {
    title: "Argentina",
    value: "AR",
    icon: "🇦🇷",
  },
  {
    title: "Armenia",
    value: "AM",
    icon: "🇦🇲",
  },
  {
    title: "Aruba",
    value: "AW",
    icon: "🇦🇼",
  },
  {
    title: "Australia",
    value: "AU",
    icon: "🇦🇺",
  },
  {
    title: "Azerbaijan",
    value: "AZ",
    icon: "🇦🇿",
  },
  {
    title: "Bahamas",
    value: "BS",
    icon: "🇧🇸",
  },
  {
    title: "Bahrain",
    value: "BH",
    icon: "🇧🇭",
  },
  {
    title: "Bangladesh",
    value: "BD",
    icon: "🇧🇩",
  },
  {
    title: "Barbados",
    value: "BB",
    icon: "🇧🇧",
  },
  {
    title: "Belarus",
    value: "BY",
    icon: "🇧🇾",
  },
  {
    title: "Belgium",
    value: "BE",
    icon: "🇧🇪",
  },
  {
    title: "Belize",
    value: "BZ",
    icon: "🇧🇿",
  },
  {
    title: "Benin",
    value: "BJ",
    icon: "🇧🇯",
  },
  {
    title: "Bermuda",
    value: "BM",
    icon: "🇧🇲",
  },
  {
    title: "Bhutan",
    value: "BT",
    icon: "🇧🇹",
  },
  {
    title: "Bolivia",
    value: "BO",
    icon: "🇧🇴",
  },
  {
    title: "Bosnia and Herzegovina",
    value: "BA",
    icon: "🇧🇦",
  },
  {
    title: "Botswana",
    value: "BW",
    icon: "🇧🇼",
  },
  {
    title: "Brazil",
    value: "BR",
    icon: "🇧🇷",
  },
  {
    title: "British Virgin Islands",
    value: "VG",
    icon: "🇻🇬",
  },
  {
    title: "Brunei",
    value: "BN",
    icon: "🇧🇳",
  },
  {
    title: "Bulgaria",
    value: "BG",
    icon: "🇧🇬",
  },
  {
    title: "Burkina Faso",
    value: "BF",
    icon: "🇧🇫",
  },
  {
    title: "Burundi",
    value: "BI",
    icon: "🇧🇮",
  },
  {
    title: "Cambodia",
    value: "KH",
    icon: "🇰🇭",
  },
  {
    title: "Cameroon",
    value: "CM",
    icon: "🇨🇲",
  },
  {
    title: "Canada",
    value: "CA",
    icon: "🇨🇦",
  },
  {
    title: "Cape Verde",
    value: "CV",
    icon: "🇨🇻",
  },
  {
    title: "Cayman Islands",
    value: "KY",
    icon: "🇰🇾",
  },
  {
    title: "Central African Republic",
    value: "CF",
    icon: "🇨🇫",
  },
  {
    title: "Chad",
    value: "TD",
    icon: "🇹🇩",
  },
  {
    title: "Chile",
    value: "CL",
    icon: "🇨🇱",
  },
  {
    title: "China",
    value: "CN",
    icon: "🇨🇳",
  },
  {
    title: "Colombia",
    value: "CO",
    icon: "🇨🇴",
  },
  {
    title: "Comoros",
    value: "KM",
    icon: "🇰🇲",
  },
  {
    title: "Cook Islands",
    value: "CK",
    icon: "🇨🇰",
  },
  {
    title: "Costa Rica",
    value: "CR",
    icon: "🇨🇷",
  },
  {
    title: "Croatia",
    value: "HR",
    icon: "🇭🇷",
  },
  {
    title: "Cuba",
    value: "CU",
    icon: "🇨🇺",
  },
  {
    title: "Curacao",
    value: "CW",
    icon: "🇨🇼",
  },
  {
    title: "Cyprus",
    value: "CY",
    icon: "🇨🇾",
  },
  {
    title: "Czech Republic",
    value: "CZ",
    icon: "🇨🇿",
  },
  {
    title: "Democratic Republic of the Congo",
    value: "CD",
    icon: "🇨🇩",
  },
  {
    title: "Denmark",
    value: "DK",
    icon: "🇩🇰",
  },
  {
    title: "Djibouti",
    value: "DJ",
    icon: "🇩🇯",
  },
  {
    title: "Dominica",
    value: "DM",
    icon: "🇩🇲",
  },
  {
    title: "Dominican Republic",
    value: "DO",
    icon: "🇩🇴",
  },
  {
    title: "East Timor",
    value: "TL",
    icon: "🇹🇱",
  },
  {
    title: "Ecuador",
    value: "EC",
    icon: "🇪🇨",
  },
  {
    title: "Egypt",
    value: "EG",
    icon: "🇪🇬",
  },
  {
    title: "El Salvador",
    value: "SV",
    icon: "🇸🇻",
  },
  {
    title: "Eritrea",
    value: "ER",
    icon: "🇪🇷",
  },
  {
    title: "Estonia",
    value: "EE",
    icon: "🇪🇪",
  },
  {
    title: "Ethiopia",
    value: "ET",
    icon: "🇪🇹",
  },
  {
    title: "Faroe Islands",
    value: "FO",
    icon: "🇫🇴",
  },
  {
    title: "Fiji",
    value: "FJ",
    icon: "🇫🇯",
  },
  {
    title: "Finland",
    value: "FI",
    icon: "🇫🇮",
  },
  {
    title: "France",
    value: "FR",
    icon: "🇫🇷",
  },
  {
    title: "French Polynesia",
    value: "PF",
    icon: "🇵🇫",
  },
  {
    title: "Gabon",
    value: "GA",
    icon: "🇬🇦",
  },
  {
    title: "Gambia",
    value: "GM",
    icon: "🇬🇲",
  },
  {
    title: "Georgia",
    value: "GE",
    icon: "🇬🇪",
  },
  {
    title: "Germany",
    value: "DE",
    icon: "🇩🇪",
  },
  {
    title: "Ghana",
    value: "GH",
    icon: "🇬🇭",
  },
  {
    title: "Greece",
    value: "GR",
    icon: "🇬🇷",
  },
  {
    title: "Greenland",
    value: "GL",
    icon: "🇬🇱",
  },
  {
    title: "Grenada",
    value: "GD",
    icon: "🇬🇩",
  },
  {
    title: "Guam",
    value: "GU",
    icon: "🇬🇺",
  },
  {
    title: "Guatemala",
    value: "GT",
    icon: "🇬🇹",
  },
  {
    title: "Guernsey",
    value: "GG",
    icon: "🇬🇬",
  },
  {
    title: "Guinea",
    value: "GN",
    icon: "🇬🇳",
  },
  {
    title: "Guinea-Bissau",
    value: "GW",
    icon: "🇬🇼",
  },
  {
    title: "Guyana",
    value: "GY",
    icon: "🇬🇾",
  },
  {
    title: "Haiti",
    value: "HT",
    icon: "🇭🇹",
  },
  {
    title: "Honduras",
    value: "HN",
    icon: "🇭🇳",
  },
  {
    title: "Hong Kong",
    value: "HK",
    icon: "🇭🇰",
  },
  {
    title: "Hungary",
    value: "HU",
    icon: "🇭🇺",
  },
  {
    title: "Iceland",
    value: "IS",
    icon: "🇮🇸",
  },
  {
    title: "India",
    value: "IN",
    icon: "🇮🇳",
  },
  {
    title: "Indonesia",
    value: "ID",
    icon: "🇮🇩",
  },
  {
    title: "Iran",
    value: "IR",
    icon: "🇮🇷",
  },
  {
    title: "Iraq",
    value: "IQ",
    icon: "🇮🇶",
  },
  {
    title: "Ireland",
    value: "IE",
    icon: "🇮🇪",
  },
  {
    title: "Isle of Man",
    value: "IM",
    icon: "🇮🇲",
  },
  {
    title: "Israel",
    value: "IL",
    icon: "🇮🇱",
  },
  {
    title: "Italy",
    value: "IT",
    icon: "🇮🇹",
  },
  {
    title: "Ivory Coast",
    value: "CI",
    icon: "🇨🇮",
  },
  {
    title: "Jamaica",
    value: "JM",
    icon: "🇯🇲",
  },
  {
    title: "Japan",
    value: "JP",
    icon: "🇯🇵",
  },
  {
    title: "Jersey",
    value: "JE",
    icon: "🇯🇪",
  },
  {
    title: "Jordan",
    value: "JO",
    icon: "🇯🇴",
  },
  {
    title: "Kazakhstan",
    value: "KZ",
    icon: "🇰🇿",
  },
  {
    title: "Kenya",
    value: "KE",
    icon: "🇰🇪",
  },
  {
    title: "Kiribati",
    value: "KI",
    icon: "🇰🇮",
  },
  {
    title: "Kosovo",
    value: "XK",
    icon: "🇽🇰",
  },
  {
    title: "Kuwait",
    value: "KW",
    icon: "🇰🇼",
  },
  {
    title: "Kyrgyzstan",
    value: "KG",
    icon: "🇰🇬",
  },
  {
    title: "Laos",
    value: "LA",
    icon: "🇱🇦",
  },
  {
    title: "Latvia",
    value: "LV",
    icon: "🇱🇻",
  },
  {
    title: "Lebanon",
    value: "LB",
    icon: "🇱🇧",
  },
  {
    title: "Lesotho",
    value: "LS",
    icon: "🇱🇸",
  },
  {
    title: "Liberia",
    value: "LR",
    icon: "🇱🇷",
  },
  {
    title: "Libya",
    value: "LY",
    icon: "🇱🇾",
  },
  {
    title: "Liechtenstein",
    value: "LI",
    icon: "🇱🇮",
  },
  {
    title: "Lithuania",
    value: "LT",
    icon: "🇱🇹",
  },
  {
    title: "Luxembourg",
    value: "LU",
    icon: "🇱🇺",
  },
  {
    title: "Macau",
    value: "MO",
    icon: "🇲🇴",
  },
  {
    title: "Macedonia",
    value: "MK",
    icon: "🇲🇰",
  },
  {
    title: "Madagascar",
    value: "MG",
    icon: "🇲🇬",
  },
  {
    title: "Malawi",
    value: "MW",
    icon: "🇲🇼",
  },
  {
    title: "Malaysia",
    value: "MY",
    icon: "🇲🇾",
  },
  {
    title: "Maldives",
    value: "MV",
    icon: "🇲🇻",
  },
  {
    title: "Mali",
    value: "ML",
    icon: "🇲🇱",
  },
  {
    title: "Malta",
    value: "MT",
    icon: "🇲🇹",
  },
  {
    title: "Marshall Islands",
    value: "MH",
    icon: "🇲🇭",
  },
  {
    title: "Mauritania",
    value: "MR",
    icon: "🇲🇷",
  },
  {
    title: "Mauritius",
    value: "MU",
    icon: "🇲🇺",
  },
  {
    title: "Mayotte",
    value: "YT",
    icon: "🇾🇹",
  },
  {
    title: "Mexico",
    value: "MX",
    icon: "🇲🇽",
  },
  {
    title: "Micronesia",
    value: "FM",
    icon: "🇫🇲",
  },
  {
    title: "Moldova",
    value: "MD",
    icon: "🇲🇩",
  },
  {
    title: "Monaco",
    value: "MC",
    icon: "🇲🇨",
  },
  {
    title: "Mongolia",
    value: "MN",
    icon: "🇲🇳",
  },
  {
    title: "Montenegro",
    value: "ME",
    icon: "🇲🇪",
  },
  {
    title: "Morocco",
    value: "MA",
    icon: "🇲🇦",
  },
  {
    title: "Mozambique",
    value: "MZ",
    icon: "🇲🇿",
  },
  {
    title: "Myanmar",
    value: "MM",
    icon: "🇲🇲",
  },
  {
    title: "Namibia",
    value: "NA",
    icon: "🇳🇦",
  },
  {
    title: "Nepal",
    value: "NP",
    icon: "🇳🇵",
  },
  {
    title: "Netherlands",
    value: "NL",
    icon: "🇳🇱",
  },
  {
    title: "Netherlands Antilles",
    value: "AN",
    icon: "🇦🇳",
  },
  {
    title: "New Caledonia",
    value: "NC",
    icon: "🇳🇨",
  },
  {
    title: "New Zealand",
    value: "NZ",
    icon: "🇳🇿",
  },
  {
    title: "Nicaragua",
    value: "NI",
    icon: "🇳🇮",
  },
  {
    title: "Niger",
    value: "NE",
    icon: "🇳🇪",
  },
  {
    title: "Nigeria",
    value: "NG",
    icon: "🇳🇬",
  },
  {
    title: "North Korea",
    value: "KP",
    icon: "🇰🇵",
  },
  {
    title: "Northern Mariana Islands",
    value: "MP",
    icon: "🇲🇵",
  },
  {
    title: "Norway",
    value: "NO",
    icon: "🇳🇴",
  },
  {
    title: "Oman",
    value: "OM",
    icon: "🇴🇲",
  },
  {
    title: "Pakistan",
    value: "PK",
    icon: "🇵🇰",
  },
  {
    title: "Palestine",
    value: "PS",
    icon: "🇵🇸",
  },
  {
    title: "Panama",
    value: "PA",
    icon: "🇵🇦",
  },
  {
    title: "Papua New Guinea",
    value: "PG",
    icon: "🇵🇬",
  },
  {
    title: "Paraguay",
    value: "PY",
    icon: "🇵🇾",
  },
  {
    title: "Peru",
    value: "PE",
    icon: "🇵🇪",
  },
  {
    title: "Philippines",
    value: "PH",
    icon: "🇵🇭",
  },
  {
    title: "Poland",
    value: "PL",
    icon: "🇵🇱",
  },
  {
    title: "Portugal",
    value: "PT",
    icon: "🇵🇹",
  },
  {
    title: "Puerto Rico",
    value: "PR",
    icon: "🇵🇷",
  },
  {
    title: "Qatar",
    value: "QA",
    icon: "🇶🇦",
  },
  {
    title: "Republic of the Congo",
    value: "CG",
    icon: "🇨🇬",
  },
  {
    title: "Reunion",
    value: "RE",
    icon: "🇷🇪",
  },
  {
    title: "Romania",
    value: "RO",
    icon: "🇷🇴",
  },
  {
    title: "Russia",
    value: "RU",
    icon: "🇷🇺",
  },
  {
    title: "Rwanda",
    value: "RW",
    icon: "🇷🇼",
  },
  {
    title: "Saint Kitts and Nevis",
    value: "KN",
    icon: "🇰🇳",
  },
  {
    title: "Saint Lucia",
    value: "LC",
    icon: "🇱🇨",
  },
  {
    title: "Saint Martin",
    value: "MF",
    icon: "🇲🇫",
  },
  {
    title: "Saint Pierre and Miquelon",
    value: "PM",
    icon: "🇵🇲",
  },
  {
    title: "Saint Vincent and the Grenadines",
    value: "VC",
    icon: "🇻🇨",
  },
  {
    title: "Samoa",
    value: "WS",
    icon: "🇼🇸",
  },
  {
    title: "San Marino",
    value: "SM",
    icon: "🇸🇲",
  },
  {
    title: "Sao Tome and Principe",
    value: "ST",
    icon: "🇸🇹",
  },
  {
    title: "Saudi Arabia",
    value: "SA",
    icon: "🇸🇦",
  },
  {
    title: "Senegal",
    value: "SN",
    icon: "🇸🇳",
  },
  {
    title: "Serbia",
    value: "RS",
    icon: "🇷🇸",
  },
  {
    title: "Seychelles",
    value: "SC",
    icon: "🇸🇨",
  },
  {
    title: "Sierra Leone",
    value: "SL",
    icon: "🇸🇱",
  },
  {
    title: "Singapore",
    value: "SG",
    icon: "🇸🇬",
  },
  {
    title: "Sint Maarten",
    value: "SX",
    icon: "🇸🇽",
  },
  {
    title: "Slovakia",
    value: "SK",
    icon: "🇸🇰",
  },
  {
    title: "Slovenia",
    value: "SI",
    icon: "🇸🇮",
  },
  {
    title: "Solomon Islands",
    value: "SB",
    icon: "🇸🇧",
  },
  {
    title: "Somalia",
    value: "SO",
    icon: "🇸🇴",
  },
  {
    title: "South Africa",
    value: "ZA",
    icon: "🇿🇦",
  },
  {
    title: "South Korea",
    value: "KR",
    icon: "🇰🇷",
  },
  {
    title: "South Sudan",
    value: "SS",
    icon: "🇸🇸",
  },
  {
    title: "Spain",
    value: "ES",
    icon: "🇪🇸",
  },
  {
    title: "Sri Lanka",
    value: "LK",
    icon: "🇱🇰",
  },
  {
    title: "Sudan",
    value: "SD",
    icon: "🇸🇩",
  },
  {
    title: "Suriname",
    value: "SR",
    icon: "🇸🇷",
  },
  {
    title: "Swaziland",
    value: "SZ",
    icon: "🇸🇿",
  },
  {
    title: "Sweden",
    value: "SE",
    icon: "🇸🇪",
  },
  {
    title: "Switzerland",
    value: "CH",
    icon: "🇨🇭",
  },
  {
    title: "Syria",
    value: "SY",
    icon: "🇸🇾",
  },
  {
    title: "Taiwan",
    value: "TW",
    icon: "🇹🇼",
  },
  {
    title: "Tajikistan",
    value: "TJ",
    icon: "🇹🇯",
  },
  {
    title: "Tanzania",
    value: "TZ",
    icon: "🇹🇿",
  },
  {
    title: "Thailand",
    value: "TH",
    icon: "🇹🇭",
  },
  {
    title: "Togo",
    value: "TG",
    icon: "🇹🇬",
  },
  {
    title: "Tonga",
    value: "TO",
    icon: "🇹🇴",
  },
  {
    title: "Trinidad and Tobago",
    value: "TT",
    icon: "🇹🇹",
  },
  {
    title: "Tunisia",
    value: "TN",
    icon: "🇹🇳",
  },
  {
    title: "Turkey",
    value: "TR",
    icon: "🇹🇷",
  },
  {
    title: "Turkmenistan",
    value: "TM",
    icon: "🇹🇲",
  },
  {
    title: "Turks and Caicos Islands",
    value: "TC",
    icon: "🇹🇨",
  },
  {
    title: "Tuvalu",
    value: "TV",
    icon: "🇹🇻",
  },
  {
    title: "U.S. Virgin Islands",
    value: "VI",
    icon: "🇻🇮",
  },
  {
    title: "Uganda",
    value: "UG",
    icon: "🇺🇬",
  },
  {
    title: "Ukraine",
    value: "UA",
    icon: "🇺🇦",
  },
  {
    title: "United Arab Emirates",
    value: "AE",
    icon: "🇦🇪",
  },
  {
    title: "United Kingdom",
    value: "GB",
    icon: "🇬🇧",
  },
  {
    title: "United States",
    value: "US",
    icon: "🇺🇸",
  },
  {
    title: "Uruguay",
    value: "UY",
    icon: "🇺🇾",
  },
  {
    title: "Uzbekistan",
    value: "UZ",
    icon: "🇺🇿",
  },
  {
    title: "Vanuatu",
    value: "VU",
    icon: "🇻🇺",
  },
  {
    title: "Venezuela",
    value: "VE",
    icon: "🇻🇪",
  },
  {
    title: "Vietnam",
    value: "VN",
    icon: "🇻🇳",
  },
  {
    title: "Western Sahara",
    value: "EH",
    icon: "🇪🇭",
  },
  {
    title: "Yemen",
    value: "YE",
    icon: "🇾🇪",
  },
  {
    title: "Zambia",
    value: "ZM",
    icon: "🇿🇲",
  },
  {
    title: "Zimbabwe",
    value: "ZW",
    icon: "🇿🇼",
  },
];
