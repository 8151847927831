import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  addNewCommentCase,
  detailCase,
  duplicateCase,
  getListCommentCase,
  updateAssignedCase,
} from "../../redux/actions/caseActions";
import DefaultLayout from "../../layouts/DefaultLayout";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import { baseURLFile, COUNTRIES, CURRENCYITEMS } from "../../constants";

import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { getListCoordinators } from "../../redux/actions/userActions";
import { CASE_DUPLICATE_REQUEST } from "../../redux/constants/caseConstants";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

function DetailCaseScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";

  const [isLoading, setIsLoading] = useState(false);
  const [openDiag, setOpenDiag] = useState(false);
  const [selectCoordinator, setSelectCoordinator] = useState("");
  const [selectCoordinatorError, setSelectCoordinatorError] = useState("");

  const [selectPage, setSelectPage] = useState("General Information");
  const [commentInput, setCommentInput] = useState("");
  const [commentInputError, setCommentInputError] = useState("");

  const [isDuplicate, setIsDuplicate] = useState(false);

  // files comment
  // initialMedicalReports
  const [filesComments, setFilesComments] = useState([]);
  const { getRootProps: getRootComments, getInputProps: getInputComments } =
    useDropzone({
      accept: {
        "image/*": [],
      },
      onDrop: (acceptedFiles) => {
        setFilesComments((prevFiles) => [
          ...prevFiles,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          ),
        ]);
      },
    });

  useEffect(() => {
    return () =>
      filesComments.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  //
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const caseDetail = useSelector((state) => state.detailCase);
  const { loadingCaseInfo, errorCaseInfo, successCaseInfo, caseInfo } =
    caseDetail;

  const listCommentCase = useSelector((state) => state.commentCaseList);
  const { comments, loadingCommentCase, errorCommentCase, pages } =
    listCommentCase;

  const createCommentCase = useSelector((state) => state.createNewCommentCase);
  const { loadingCommentCaseAdd, successCommentCaseAdd, errorCommentCaseAdd } =
    createCommentCase;

  const listCoordinators = useSelector((state) => state.coordinatorsList);
  const { coordinators, loadingCoordinators, errorCoordinators } =
    listCoordinators;

  const caseAssignedUpdate = useSelector((state) => state.updateCaseAssigned);
  const {
    loadingCaseAssignedUpdate,
    errorCaseAssignedUpdate,
    successCaseAssignedUpdate,
  } = caseAssignedUpdate;

  const caseDuplicat = useSelector((state) => state.duplicateCase);
  const {
    loadingCaseDuplicate,
    errorCaseDuplicate,
    successCaseDuplicate,
    caseDuplicate,
  } = caseDuplicat;
  //
  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(detailCase(id));
      dispatch(getListCommentCase("0", id));
      dispatch(getListCoordinators("0"));
    }
  }, [navigate, userInfo, dispatch, id, page]);

  useEffect(() => {
    if (successCommentCaseAdd) {
      setCommentInput("");
      setCommentInputError("");
      setFilesComments([]);
      dispatch(getListCommentCase("0", id));
    }
  }, [successCommentCaseAdd]);

  useEffect(() => {
    if (successCaseDuplicate && caseDuplicate) {
      navigate("/cases/edit/" + caseDuplicate);
      dispatch({ type: "RESET_DUPLICATE_CASE" });
    }
  }, [successCaseDuplicate, caseDuplicate]);

  // Reset flag on navigation back
  useEffect(() => {
    return () => setIsDuplicate(false);
  }, []);

  useEffect(() => {
    if (successCaseAssignedUpdate) {
      setSelectCoordinator("");
      setSelectCoordinatorError("");
      setOpenDiag(false);
      dispatch(detailCase(id));
      dispatch(getListCommentCase("0", id));
      dispatch(getListCoordinators("0"));
    }
  }, [successCaseAssignedUpdate]);

  const formatDate = (dateString) => {
    if (dateString && dateString !== "") {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      return dateString;
    }
  };

  const caseStatus = (casestatus) => {
    switch (casestatus) {
      case "pending-coordination":
        return "Pending Coordination";
      case "coordinated-missing-m-r":
        return "Coordinated, Missing M.R.";
      case "coordinated-missing-invoice":
        return "Coordinated, Missing Invoice";
      case "waiting-for-insurance-authorization":
        return "Waiting for Insurance Authorization";
      case "coordinated-patient-not-seen-yet":
        return "Coordinated, Patient not seen yet";
      case "fully-coordinated":
        return "Fully Coordinated";
      case "coordination-fee":
        return "Coordination Fee";
      case "coordinated-missing-payment":
        return "Coordinated, Missing Payment";
      case "failed":
        return "Failed";
      default:
        return casestatus;
    }
  };

  const caseStatusColor = (casestatus) => {
    switch (casestatus) {
      case "pending-coordination":
        return "text-danger";
      case "coordinated-missing-m-r":
        return "text-[#FFA500]";
      case "coordinated-missing-invoice":
        return "text-[#FFA500]";
      case "waiting-for-insurance-authorization":
        return "text-primary";
      case "coordinated-patient-not-seen-yet":
        return "text-primary";
      case "fully-coordinated":
        return "text-[#008000]";
      case "failed":
        return "text-[#d34053]";
      default:
        return "";
    }
  };

  const getIconCountry = (country) => {
    const foundCountry = COUNTRIES.find((option) => option.title === country);

    if (foundCountry) {
      return foundCountry.icon;
    } else {
      return "";
    }
  };

  //
  const getCurrencyCode = (code) => {
    const patientCurrency = code ?? "";

    const foundCurrency = CURRENCYITEMS?.find(
      (option) => option.code === patientCurrency
    );

    if (foundCurrency) {
      return foundCurrency.symbol ?? code;
    } else {
      return code;
    }
  };

  const getSectionIndex = (selectItem) => {
    if (selectItem === "General Information") {
      return 0;
    } else if (selectItem === "Coordination Details") {
      return 1;
    } else if (selectItem === "Medical Reports") {
      return 2;
    } else if (selectItem === "Invoices") {
      return 3;
    } else if (selectItem === "Insurance Authorization") {
      return 4;
    } else {
      return 0;
    }
  };

  //
  return (
    <DefaultLayout>
      <div className="">
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/cases-list">
            <div className="">Cases List</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Case Page</div>
        </div>
        {/*  */}

        {loadingCaseInfo ? (
          <Loader />
        ) : errorCaseInfo ? (
          <Alert type={"error"} message={errorCaseInfo} />
        ) : caseInfo ? (
          <div>
            {/* info top */}
            <div className="my-2 bg-white shadow-1 px-3 py-4 rounded">
              <div className="flex md:flex-row flex-col justify-between my-1">
                <div className=" text-[#32475C] text-md font-medium opacity-85 ml-1 md:my-0 my-1 md:hidden">
                  #{caseInfo.id}
                </div>
                <div className="w-3"></div>

                <div className="flex flex-row items-center ml-1 md:my-0 my-1">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-4 mx-1 text-[#303030]  opacity-80 "
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672Zm-7.518-.267A8.25 8.25 0 1 1 20.25 10.5M8.288 14.212A5.25 5.25 0 1 1 17.25 10.5"
                      />
                    </svg>
                  </div>
                  <div className="mx-1 text-[#303030] text-sm opacity-80 ">
                    <span className="font-semibold">Created By:</span>{" "}
                    {caseInfo.created_user?.full_name ?? "---"}
                  </div>
                </div>
              </div>
              <div className="md:flex hidden  flex-row justify-end">
                <button
                  onClick={() => {
                    setSelectCoordinator(caseInfo.coordinator_user?.id ?? "");
                    setSelectCoordinatorError("");
                    setOpenDiag(true);
                    setIsLoading(false);
                  }}
                  className="flex flex-row items-center bg-primary text-white px-2 py-1 rounded "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-4 mx-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                  <div className="mx-1 text-sm"> Assigned Coordinator </div>
                </button>
              </div>

              <div className="flex md:flex-row flex-col md:items-center my-1">
                <div className=" text-[#32475C] text-md font-medium opacity-85 ml-1 md:my-0 my-1 md:block hidden">
                  #{caseInfo.id}
                </div>
                <div className="flex flex-row items-center ml-1 md:my-0 my-1">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-4 mx-1 text-[#303030]  opacity-80 "
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v7.5m2.25-6.466a9.016 9.016 0 0 0-3.461-.203c-.536.072-.974.478-1.021 1.017a4.559 4.559 0 0 0-.018.402c0 .464.336.844.775.994l2.95 1.012c.44.15.775.53.775.994 0 .136-.006.27-.018.402-.047.539-.485.945-1.021 1.017a9.077 9.077 0 0 1-3.461-.203M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                      />
                    </svg>
                  </div>
                  <div className="mx-1 text-[#303030] text-sm opacity-80 ">
                    {caseInfo.is_pay ? (
                      <span className="font-semibold bg-primary px-2 py-1 text-white">
                        Paid
                      </span>
                    ) : (
                      <span className="font-semibold bg-danger  px-2 py-1 text-white">
                        Unpaid
                      </span>
                    )}
                  </div>
                  <div className=""></div>
                </div>
                <div className="flex flex-row items-center ml-1 md:my-0 my-1">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-4 mx-1 text-[#303030]  opacity-80 "
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                      />
                    </svg>
                  </div>
                  <div className="mx-1 text-[#303030] text-sm opacity-80 ">
                    <span className="font-semibold">CIA:</span>{" "}
                    {caseInfo.assurance?.assurance_name ?? "---"}
                  </div>
                  <div className=""></div>
                </div>
                <div className="flex flex-row items-center ml-1 md:my-0 my-1">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-4 mx-1 text-[#303030]  opacity-80 "
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                      />
                    </svg>
                  </div>
                  <div className="mx-1 text-[#303030] text-sm opacity-80 ">
                    <span className="font-semibold">Full Name:</span>{" "}
                    {caseInfo.patient?.full_name ?? "---"}
                  </div>
                </div>

                <div className="flex flex-row items-center ml-1 md:my-0 my-1">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-4 mx-1 text-[#303030]  opacity-80 "
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                      />
                    </svg>
                  </div>
                  <div className="mx-1  text-sm items-center  ">
                    <span className="font-semibold text-[#303030] opacity-80">
                      Country:
                    </span>{" "}
                    {getIconCountry(caseInfo.patient?.patient_country ?? "")}{" "}
                    <span className="text-[#303030] opacity-80">
                      {caseStatus(caseInfo.patient?.patient_country)}
                    </span>
                  </div>
                  <div className=""></div>
                </div>
              </div>

              <div className="flex md:flex-row flex-col md:items-center my-1">
                <div className="flex flex-row items-center ml-1 md:my-0 my-1">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-4 mx-1 text-[#303030]  opacity-80 "
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m4.5 12.75 6 6 9-13.5"
                      />
                    </svg>
                  </div>
                  <div className="mx-1 text-[#303030] text-sm opacity-80 ">
                    <span className="font-semibold">Status:</span>{" "}
                    {caseInfo.case_status?.map((stat, index) => (
                      <>
                        <span
                          className={caseStatusColor(stat.status_coordination)}
                        >
                          {caseStatus(stat.status_coordination)}
                        </span>
                        {"- "}
                      </>
                    ))}
                  </div>
                  <div className=""></div>
                </div>
              </div>
              <div className="flex flex-row justify-end md:hidden">
                <button
                  onClick={() => {
                    setSelectCoordinator(caseInfo.coordinator_user?.id ?? "");
                    setSelectCoordinatorError("");
                    setOpenDiag(true);
                    setIsLoading(false);
                  }}
                  className="flex flex-row items-center bg-primary text-white px-2 py-1 rounded "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-4 mx-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                  <div className="mx-1 text-sm"> Assigned Coordinator </div>
                </button>
              </div>
            </div>
            {/* info others */}
            <div className="my-2 bg-white shadow-1 px-3 py-4 rounded">
              <div className="flex flex-row items-center">
                <a
                  className="text-white bg-primary px-3 py-2 rounded text-xs font-bold flex flex-row w-max items-center mx-2"
                  href={
                    "/cases/edit/" +
                    caseInfo.id +
                    "?section=" +
                    getSectionIndex(selectPage)
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                      />
                    </svg>
                  </span>
                  <span className="mx-1">Edit Case</span>
                </a>
                {/* <button
                  disabled={loadingCaseDuplicate}
                  onClick={() => {
                    dispatch(duplicateCase(caseInfo.id));
                  }}
                  className="text-white bg-success px-3 py-2 rounded text-xs font-bold flex flex-row w-max items-center mx-2"
                  // href={"/cases/edit/" + caseInfo.id}
                >
                  <span>
                    {loadingCaseDuplicate ? (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="size-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                        />
                      </svg>
                    )}
                  </span>
                  <span className="mx-1">Duplicate Case</span>
                </button> */}
              </div>
              <div className="my-3 mx-2 bg-white shadow-2 py-3 flex flex-wrap md:rounded-full rounded md:justify-between px-2">
                {[
                  "General Information",
                  "Coordination Details",
                  "Medical Reports",
                  "Invoices",
                  "Insurance Authorization",
                ].map((select, index) => (
                  <button
                    onClick={() => setSelectPage(select)}
                    className={`px-4 py-1 md:my-0 my-1  text-sm ${
                      selectPage === select
                        ? "rounded-full bg-[#0388A6] text-white font-medium "
                        : "font-normal text-[#838383]"
                    }`}
                  >
                    {select}
                  </button>
                ))}
              </div>
              {/* General Information */}
              {selectPage === "General Information" ? (
                <div className="my-3 mx-2 bg-white shadow-2 py-3 px-3 flex md:flex-row flex-col rounded ">
                  <div className="md:w-1/2 w-full px-2 y-2">
                    <div className="my-2 text-xs text-[#303030] opacity-80">
                      Patient Details
                    </div>
                    <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                      <div className="font-semibold">Name:</div>
                      <div className="flex-1 mx-1">
                        {caseInfo.patient?.full_name ?? "---"}
                      </div>
                    </div>
                    <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                      <div className="font-semibold">Date of Birth:</div>
                      <div className="flex-1 mx-1">
                        {caseInfo.patient?.birth_day ?? "---"}
                      </div>
                    </div>
                    <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                      <div className="font-semibold">Phone:</div>
                      <div className="flex-1 mx-1">
                        {caseInfo.patient?.patient_phone ?? "---"}
                      </div>
                    </div>
                    <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                      <div className="font-semibold">Email:</div>
                      <div className="flex-1 mx-1">
                        {caseInfo.patient?.patient_email ?? "---"}
                      </div>
                    </div>
                    {/* <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                      <div className="font-semibold">Address:</div>
                      <div className="flex-1 mx-1">
                        {caseInfo.patient?.patient_address ?? "---"}
                      </div>
                    </div> */}
                    <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                      <div className="font-semibold">Country:</div>
                      <div className="flex-1 mx-1">
                        {caseInfo.patient?.patient_country ?? "---"}
                      </div>
                    </div>
                    <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                      <div className="font-semibold">City:</div>
                      <div className="flex-1 mx-1">
                        {caseInfo.patient?.patient_city ?? "---"}
                      </div>
                    </div>
                  </div>
                  <div className="md:w-1/2 w-full  px-2 y-2">
                    <div className="my-2 text-xs text-[#303030] opacity-80">
                      Case Details
                    </div>
                    <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                      <div className="font-semibold">Price of service :</div>
                      <div className="flex-1 mx-1">
                        {parseFloat(caseInfo.price_tatal).toFixed(2) +
                          "" +
                          getCurrencyCode(caseInfo.currency_price ?? "")}
                      </div>
                    </div>
                    <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                      <div className="font-semibold">
                        Price of service (EUR) :
                      </div>
                      <div className="flex-1 mx-1">
                        {parseFloat(caseInfo.eur_price).toFixed(2) +
                          "" +
                          getCurrencyCode("EUR")}
                      </div>
                    </div>

                    <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                      <div className="font-semibold">Case Creation Date:</div>
                      <div className="flex-1 mx-1">
                        {formatDate(caseInfo.case_date)}
                      </div>
                    </div>
                    <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                      <div className="font-semibold">Assigned Coordinator:</div>
                      <div className="flex-1 mx-1">
                        {caseInfo.coordinator_user?.full_name ?? "---"}
                      </div>
                    </div>
                    <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                      <div className="font-semibold">Description:</div>
                      <div className="flex-1 mx-1">
                        {caseInfo.case_description ?? "---"}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Coordination Details */}
              {selectPage === "Coordination Details" ? (
                <div>
                  <div className="my-3 mx-2 bg-white shadow-2 py-3 px-3 flex md:flex-row flex-col rounded ">
                    <div className="md:w-1/2 w-full px-2 y-2">
                      <div className="my-2 text-xs text-[#303030] opacity-80">
                        Coordination Status
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">Current Status:</div>
                        <div className="flex-1 mx-1">
                          {caseInfo.status_coordination ?? "---"}
                        </div>
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">Last Updated Date:</div>
                        <div className="flex-1 mx-1">
                          {formatDate(caseInfo.updated_at)}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full  px-2 y-2">
                      <div className="my-2 text-xs text-[#303030] opacity-80">
                        Appointment Details
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">
                          Scheduled Appointment Date:
                        </div>
                        <div className="flex-1 mx-1">
                          {formatDate(caseInfo.appointment_date)}
                        </div>
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">Service Location:</div>
                        <div className="flex-1 mx-1">
                          {caseInfo.service_location ?? "---"}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="my-3 mx-2 bg-white shadow-2 py-3 px-3 flex md:flex-row flex-col rounded ">
                    <div className=" w-full px-2 y-2">
                      <div className="my-2 text-xs text-[#303030] opacity-80">
                        Providers Informations
                      </div>
                      {caseInfo.provider_services?.map((provider, index) => (
                        <div>
                          <a
                            href={`/providers-list/profile/${provider.provider?.id}`}
                            className="text-[#303030] text-sm opacity-80 my-3 flex flex-row items-center hover:text-primary"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-4 mx-1"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                              />
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                            </svg>

                            <div className="font-semibold">Provider Name:</div>
                            <div className="flex-1 mx-1">
                              {provider.provider?.full_name ?? "---"}
                            </div>
                          </a>
                          <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                            <div className="font-semibold">Service:</div>
                            <div className="flex-1 mx-1">
                              {provider.provider_service?.service_type +
                                (provider.provider_service
                                  ?.service_specialist !== ""
                                  ? ": " +
                                    provider.provider_service
                                      ?.service_specialist
                                  : "")}
                            </div>
                          </div>
                          <div>---------</div>
                        </div>
                      ))}
                      {/* <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row"> */}
                      {/* <div className="font-semibold">Provider Name:</div>
                        <div className="flex-1 mx-1">
                          {caseInfo.provider?.full_name ?? "---"}
                        </div>
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">Phone:</div>
                        <div className="flex-1 mx-1">
                          {caseInfo.provider?.phone ?? "---"}
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="md:w-1/2 w-full  px-2 y-2">
                      <div className="my-2 text-xs text-[#303030] opacity-80">
                        {" "}
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">Email:</div>
                        <div className="flex-1 mx-1">
                          {caseInfo.provider?.email ?? "---"}
                        </div>
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">Address:</div>
                        <div className="flex-1 mx-1">
                          {caseInfo.provider?.address ?? "---"}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              ) : null}
              {/* "Medical Reports" */}
              {selectPage === "Medical Reports" ? (
                <div className="my-3 mx-2 bg-white shadow-2 py-3 px-3 flex md:flex-row flex-col rounded ">
                  <div className="w-full px-2 y-2">
                    <div className="my-2 text-xs text-[#303030] opacity-80">
                      Uploaded Documents
                    </div>
                    <div className="flex flex-wrap">
                      {caseInfo.medical_reports?.map((item, index) => (
                        <a
                          href={baseURLFile + item.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="md:w-1/3 w-full px-2 py-2 flex "
                        >
                          <div className="bg-[#F3F5F7] rounded-xl px-5 py-3 w-full flex flex-row items-center">
                            <div className="rounded-full bg-white text-[#81838E] text-center p-2 shadow-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="size-4"
                              >
                                <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                              </svg>
                            </div>
                            <div className="flex-1 px-2 text-[#303030] text-sm overflow-hidden ">
                              <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full flex-shrink text-xs">
                                {item.file_name}
                              </div>
                              <div>{item.file_size} mb</div>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
              {/* "Invoices" */}
              {selectPage === "Invoices" ? (
                <div className="my-3 mx-2 bg-white shadow-2 py-3 px-3 rounded ">
                  <div className="flex md:flex-row flex-col">
                    <div className="md:w-1/2 w-full px-2 y-2">
                      <div className="my-2 text-xs text-[#303030] opacity-80">
                        Invoice Details
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">Invoice Number:</div>
                        <div className="flex-1 mx-1">
                          {caseInfo.invoice_number ?? "---"}
                        </div>
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">Date Issued:</div>
                        <div className="flex-1 mx-1">
                          {formatDate(caseInfo.date_issued)}
                        </div>
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">Amount:</div>
                        <div className="flex-1 mx-1">
                          ${parseFloat(caseInfo.invoice_amount).toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full  px-2 y-2">
                      <div className="my-2 text-xs text-[#303030] opacity-80">
                        {" "}
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">Due Date:</div>
                        <div className="flex-1 mx-1">??</div>
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">Invoice Status:</div>
                        <div className="flex-1 mx-1">??</div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full px-2 y-2">
                    <div className="my-2 text-xs text-[#303030] opacity-80">
                      Uploaded Documents
                    </div>
                    <div className="flex flex-wrap">
                      {caseInfo.upload_invoices?.map((item, index) => (
                        <a
                          href={baseURLFile + item.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="md:w-1/3 w-full px-2 py-2 flex "
                        >
                          <div className="bg-[#F3F5F7] rounded-xl px-5 py-3 w-full flex flex-row items-center">
                            <div className="rounded-full bg-white text-[#81838E] text-center p-2 shadow-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="size-4"
                              >
                                <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                              </svg>
                            </div>
                            <div className="flex-1 px-2 text-[#303030] text-sm overflow-hidden ">
                              <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full flex-shrink text-xs">
                                {item.file_name}
                              </div>
                              <div>{item.file_size} mb</div>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
              {/* "Insurance Authorization" */}
              {selectPage === "Insurance Authorization" ? (
                <div className="my-3 mx-2 bg-white shadow-2 py-3 px-3  rounded ">
                  <div className="flex md:flex-row flex-col">
                    <div className="md:w-1/2 w-full px-2 y-2">
                      <div className="my-2 text-xs text-[#303030] opacity-80">
                        Insurance Details
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">
                          Authorization Status:
                        </div>
                        <div className="flex-1 mx-1">
                          {caseInfo.assurance_status ?? "---"}
                        </div>
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">
                          Insurance Company Name:
                        </div>
                        <div className="flex-1 mx-1">
                          {caseInfo.assurance?.assurance_name ?? "---"}
                        </div>
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">CIA Reference:</div>
                        <div className="flex-1 mx-1">
                          {caseInfo.assurance_number ?? "---"}
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full  px-2 y-2">
                      <div className="my-2 text-xs text-[#303030] opacity-80">
                        {" "}
                      </div>
                      <div className="text-[#303030] text-sm opacity-80 my-3 flex flex-row">
                        <div className="font-semibold">Policy Number:</div>
                        <div className="flex-1 mx-1">
                          {caseInfo.policy_number ?? "---"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full px-2 y-2">
                    <div className="my-2 text-xs text-[#303030] opacity-80">
                      Uploaded Documents
                    </div>
                    <div className="flex flex-wrap">
                      {caseInfo.upload_authorization?.map((item, index) => (
                        <a
                          href={baseURLFile + item.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="md:w-1/3 w-full px-2 py-2 flex "
                        >
                          <div className="bg-[#F3F5F7] rounded-xl px-5 py-3 w-full flex flex-row items-center">
                            <div className="rounded-full bg-white text-[#81838E] text-center p-2 shadow-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="size-4"
                              >
                                <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                              </svg>
                            </div>
                            <div className="flex-1 px-2 text-[#303030] text-sm overflow-hidden ">
                              <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full flex-shrink text-xs">
                                {item.file_name}
                              </div>
                              <div>{item.file_size} mb</div>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}

              {/*  */}
            </div>
            {/* comment */}
            <div className="my-2 bg-white shadow-1 px-3 py-4 rounded">
              <div className="my-3 mx-2 b py-3  px-2">
                <div className="flex md:flex-row flex-col ">
                  <div className="md:w-1/2 w-full">
                    <div className="my-1  py-1 px-2">
                      <label className="text-xs font-medium mt-5 mb-2 text-black">
                        Comment
                      </label>
                      <textarea
                        value={commentInput}
                        onChange={(v) => setCommentInput(v.target.value)}
                        className={`  ${
                          commentInputError
                            ? "border-danger"
                            : "border-[#F1F3FF]"
                        } min-h-30  outline-none border border-[#F1F3FF]  w-full rounded text-sm p-3`}
                      ></textarea>
                      <div className=" text-[8px] text-danger">
                        {commentInputError ? commentInputError : ""}
                      </div>
                    </div>
                  </div>
                  <div className="md:w-1/2 w-full">
                    <div className="my-1 bg-white py-1 px-2 rounded-md">
                      <label className="text-xs font-medium mt-5 mb-2 text-black">
                        Images
                      </label>
                      <div
                        {...getRootComments({
                          className: "dropzone",
                        })}
                        // style={dropzoneStyle}
                        className="bg-[#F5F6FF] w-full min-h-30 flex flex-col items-center justify-center cursor-pointer"
                      >
                        <input {...getInputComments()} />
                        <div className="my-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="size-7 p-2 bg-[#0388A6] rounded-full text-white"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                            />
                          </svg>
                        </div>
                        <div className="my-2 text-sm">
                          Drag & Drop Images or BROWSE
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <aside style={thumbsContainer}>
                  <div className="w-full flex flex-col ">
                    {filesComments?.map((file, index) => (
                      <div
                        className="bg-[#F3F5F7] rounded-xl px-5 py-5 w-full my-2 flex flex-row items-center"
                        key={file.name}
                      >
                        <div className=" text-[#81838E] text-center  shadow-1 ">
                          <img
                            src={file.preview}
                            className="size-8"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/assets/placeholder.png";
                            }}
                          />
                        </div>
                        <div className="flex-1 px-5 text-[#303030] text-sm">
                          <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full flex-shrink text-xs">
                            {file.name}
                          </div>
                          <div>{(file.size / (1024 * 1024)).toFixed(2)} mb</div>
                        </div>
                        <button
                          onClick={() => {
                            setFilesComments((prevFiles) =>
                              prevFiles.filter(
                                (_, indexToRemove) => index !== indexToRemove
                              )
                            );
                          }}
                          className="rounded-full p-1 bg-[#E6E7E8] font-bold text-[#9D9D9D]"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6 18 18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                </aside>
                <div>
                  <button
                    disabled={loadingCommentCaseAdd}
                    onClick={async () => {
                      var check = true;
                      setCommentInputError("");

                      if (commentInput === "" && filesComments.length === 0) {
                        setCommentInputError("This field is required.");
                        check = false;
                      }

                      if (check) {
                        await dispatch(
                          addNewCommentCase(
                            {
                              content: commentInput,
                              // files
                              files_commet: filesComments,
                            },
                            id
                          )
                        );
                      } else {
                        toast.error(
                          "Some fields are empty or invalid. please try again"
                        );
                      }
                    }}
                    className="text-white  bg-[#0388A6] text-sm px-10 py-2 rounded-2xl"
                  >
                    {loadingCommentCaseAdd ? "Loading .." : "Save"}
                  </button>
                </div>
                <div className="my-5">
                  {loadingCommentCase ? (
                    <Loader />
                  ) : errorCommentCase ? (
                    <Alert type={"error"} message={errorCommentCase} />
                  ) : comments ? (
                    <>
                      {comments?.map((comment, index) => (
                        <div className="flex flex-row items-start">
                          <div>
                            {comment.coordinator ? (
                              comment.coordinator?.photo ? (
                                <img
                                  className=" size-12 rounded-full"
                                  src={baseURLFile + comment.coordinator?.photo}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "/assets/placeholder.png";
                                  }}
                                />
                              ) : (
                                <div className="size-12  rounded-full shadow-1 bg-[#0388A6] text-white flex flex-row items-center justify-center">
                                  <div className=" uppercase">
                                    {comment.coordinator?.first_name
                                      ? comment.coordinator?.first_name[0]
                                      : ""}
                                    {comment.coordinator?.last_name
                                      ? comment.coordinator?.last_name[0]
                                      : ""}
                                  </div>
                                </div>
                              )
                            ) : null}
                          </div>
                          <div className="flex-1 px-2">
                            <div className="flex flex-row mb-1 items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="size-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                                />
                              </svg>

                              <div className="flex-1 mx-1 text-xs">
                                {formatDate(comment.created_at)}
                              </div>
                            </div>
                            <div className="text-sm my-1 font-semibold">
                              {comment.coordinator?.full_name ?? ""}
                            </div>
                            <div className="text-sm my-1  whitespace-pre-line">
                              {comment.content ?? ""}
                            </div>
                            <div className="flex flex-wrap items-center  my-1">
                              {comment?.files?.map((file, index) => (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={baseURLFile + file.file}
                                >
                                  <img
                                    src={baseURLFile + file.file}
                                    className="size-30 shadow-1 rounded m-1"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = "/assets/placeholder.png";
                                    }}
                                  />
                                </a>
                              ))}
                            </div>
                            <hr className="text-opacity-10 bg-opacity-20 bg-[#0388A6]  text-[#0388A6] mb-3 mt-2" />
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {openDiag ? (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-99999 bg-black bg-opacity-20">
          <div className="bg-white p-6 rounded shadow-md mx-3 md:w-1/2 w-full m-2">
            <h3 className="text-lg font-bold mb-4">Assigned Coordinator</h3>
            <p className="mb-4 text-xs">Please Select Coordinator.</p>

            <div className=" w-full   my-2">
              <div className="text-[#B4B4B4] text-xs  mb-1">
                Assigned Coordinator <strong className="text-danger">*</strong>
              </div>
              <div>
                <select
                  className={` outline-none border ${
                    selectCoordinatorError
                      ? "border-danger"
                      : "border-[#F1F3FF]"
                  } px-3 py-2 w-full rounded text-sm`}
                  value={selectCoordinator}
                  onChange={(v) => setSelectCoordinator(v.target.value)}
                >
                  <option value={""}>Select Coordinator</option>
                  {coordinators?.map((item, index) => (
                    <option value={item.id}>{item.full_name}</option>
                  ))}
                </select>
                <div className=" text-[8px] text-danger">
                  {selectCoordinatorError ? selectCoordinatorError : ""}
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={async () => {
                  setSelectCoordinatorError("");

                  if (selectCoordinator === "") {
                    setSelectCoordinatorError("This field is required.");
                  } else {
                    setIsLoading(true);
                    await dispatch(
                      updateAssignedCase(id, { coordinator: selectCoordinator })
                    );
                    setIsLoading(false);
                  }
                }}
                disabled={isLoading}
              >
                {" "}
                {isLoading ? (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-danger"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Confirm"
                )}{" "}
              </button>
              <button
                className="bg-danger hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                onClick={() => {
                  setSelectCoordinator("");
                  setSelectCoordinatorError("");
                  setOpenDiag(false);
                  setIsLoading(false);
                }}
                disabled={isLoading}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </DefaultLayout>
  );
}

export default DetailCaseScreen;
